
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetPanelContainerRT () {
    return _createElement('div', { 'className': 'cm_FacetPanel' }, [this.WheelTireVehicleInfo(function () {
            function scopeWheelData1() {
                var wheelData = this.data.WheelData;
                return [wheelData ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_wheels-data',
                        'key': '15331'
                    }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment' }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment__label' }, 'Recommended Wheel Sizes')), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_axle_container' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_front-axle' }, wheelData.RearAxle ? _createElement('span', {
                        'className': 'cm_wheel-tire-vehicle-info_axle-title',
                        'key': '2012'
                    }, 'Front Sizes:') : null, wheelData.FrontAxle.Diameter ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '2125'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Diameter:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Diameter)) : null, wheelData.FrontAxle.Width ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '2451'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Width:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Width)) : null, wheelData.FrontAxle.Offset ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '2768'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Offset:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Offset)) : null), wheelData.RearAxle ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_rear-axle',
                        'key': '3105'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_axle-title' }, 'Rear Sizes:'), wheelData.RearAxle.Diameter ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '3282'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Diameter:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Diameter)) : null, wheelData.RearAxle.Width ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '3606'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Width:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Width)) : null, wheelData.RearAxle.Offset ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '3921'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Offset:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Offset)) : null) : null), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_attributes_container' }, wheelData.BoltPattern ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_bolt-pattern',
                        'key': '4346'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Bolt Pattern:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.BoltPattern)) : null, wheelData.HubBore ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_hubbore',
                        'key': '4657'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hub Bore:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.HubBore)) : null, wheelData.Hardware ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_hardware',
                        'key': '4951'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hardware:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Hardware)) : null, wheelData.Thread ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_thread',
                        'key': '5248'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Thread:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Thread)) : null, wheelData.Hex ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_hex',
                        'key': '5537'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hex:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Hex)) : null, wheelData.TPMS ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_tpms',
                        'key': '5814'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'TPMS:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.TPMS)) : null, wheelData.SeatType ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_seat-type',
                        'key': '6095'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Seat Type:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.SeatType)) : null)) : null];
            }
            function repeatTireSize2(tireSizes, tireSize, tireSizeIndex) {
                return _createElement('span', {
                    'className': 'cm_wheel-tire-vehicle-info_tire-sizes__item',
                    'key': tireSize
                }, '\n                ', tireSize, '\n              ');
            }
            function scopeTireSizes3() {
                var tireSizes = this.data.TireSizes;
                return [tireSizes?.length > 0 ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_tires-data',
                        'key': '64411'
                    }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment' }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment__label' }, 'Recommended Tire Sizes')), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_tire-sizes__container' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_wheel-tire-vehicle-info_tire-sizes' },
                        _map(tireSizes, repeatTireSize2.bind(this, tireSizes))
                    ]))) : null];
            }
            return _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info cm_mobile-hide cm_WheelTireVehicleInfo' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                'className': 'cm_wheel-tire-vehicle-info_header cm_collapsible_header facettitle',
                'onClick': this.toggleCollapsed
            }, '\n    Vehicle Wheel/Tire Info\n    ', this.collapsed ? [_createElement('svg', {
                    'className': 'cm_icon cm_icon-angle left',
                    'height': '20px',
                    'role': 'img',
                    'viewBox': '39 30 565 565',
                    'aria-hidden': 'true',
                    'key': '2530'
                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.collapsed ? [_createElement('svg', {
                    'className': 'cm_icon cm_icon-angle down',
                    'height': '20px',
                    'role': 'img',
                    'viewBox': '39 30 565 565',
                    'aria-hidden': 'true',
                    'key': '5720'
                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', { 'className': 'cm_collapsible-elem facetbody' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_inner-container' }, [this.autoSyncVisualization(function () {
                    return _createElement('div', { 'className': 'cm_autoSyncVisualization' }, _createElement('div', { 'className': 'cm_vehicle-visualization-container' }, this.visualization));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_title' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_title-vehicle' }, this.vehicleString)), scopeWheelData1.apply(this, []), scopeTireSizes3.apply(this, [])))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FilterChips(function () {
            return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'key': `${ this.field }|${ i }`,
                                        'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                    });
                                }
                                function scopeInchMm1_rangeChip() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '7210'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '2570'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                    'a',
                                    {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    },
                                    _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1_reviewChip.bind(this)),
                                    this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '4900'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'cm_btn cm_btn button button--small button--secondary cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Start over\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FacetBarWheels(function () {
            function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                            var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                            var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                            var isPopularField = popularEntries.length && unpopularEntries.length;
                            return [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                isPopularField ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '11641'
                                    }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                    _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '11644'
                                    }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                ] : null,
                                _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                            ];
                        }
                        function scopeShowSelectedValues4() {
                            var showSelectedValues = this.selectedEntries.length;
                            return _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                        }
                        function repeatEntry5(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3488'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3690'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                'className': this.extraClassName,
                                'key': '980'
                            }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '149'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled,
                                'aria-label': this.title
                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'aria-label': this.title,
                                    'key': '2798'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                            ]) : null)]));
                    }, { count: undefined })];
            }
            function scopeFacet_bar_label2() {
                var facet_bar_label = 'Shop Wheels';
                return _createElement('div', { 'className': 'cm_FacetBarWheels' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_btn button button--small button--secondary',
                    'onClick': this.discardFields,
                    'disabled': !this.hasSelection,
                    'role': 'button',
                    'tabIndex': this.hasSelection ? '0' : '-1'
                }, [_createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'className': 'cm_icon cm_icon-reset',
                        'height': '16px',
                        'viewBox': '0 0 16 16',
                        'key': '68740'
                    }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
            }
            return scopeFacet_bar_label2.apply(this, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FacetBarTires(function () {
            function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                            var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                            var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                            var isPopularField = popularEntries.length && unpopularEntries.length;
                            return [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                isPopularField ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '11641'
                                    }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                    _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '11644'
                                    }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                ] : null,
                                _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                            ];
                        }
                        function scopeShowSelectedValues4() {
                            var showSelectedValues = this.selectedEntries.length;
                            return _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                        }
                        function repeatEntry5(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3488'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3690'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                'className': this.extraClassName,
                                'key': '980'
                            }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '149'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled,
                                'aria-label': this.title
                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'aria-label': this.title,
                                    'key': '2798'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                            ]) : null)]));
                    }, { count: undefined })];
            }
            function scopeFacet_bar_label2() {
                var facet_bar_label = 'Shop Tires';
                return _createElement('div', { 'className': 'cm_FacetBarTires' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_btn button button--small button--secondary',
                    'onClick': this.discardFields,
                    'disabled': !this.hasSelection,
                    'role': 'button',
                    'tabIndex': this.hasSelection ? '0' : '-1'
                }, [_createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'className': 'cm_icon cm_icon-reset',
                        'height': '16px',
                        'viewBox': '0 0 16 16',
                        'key': '68720'
                    }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
            }
            return scopeFacet_bar_label2.apply(this, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FacetPanel(function () {
            return _createElement('div', { 'className': 'cm_FacetPanel' }, [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', { 'className': 'facetentrykey' }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', { 'className': 'facetentrykey' }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1541'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1543'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '3901'
                                                }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                        }, { count: undefined })];
                                }
                                function scopeInchMm4() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', {
                                        'className': 'cmTemplate_sliderFacet',
                                        'key': '12543'
                                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                'className': 'separator',
                                                'key': '334'
                                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn cm_btn button button--small button--secondary',
                                                'onClick': this.setCustomRange
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]);
                                }
                                function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues6(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                                    _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0',
                                        'key': '561'
                                    }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle left',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '2380'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle down',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '5590'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null),
                                    _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'key': '563'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'role': 'list',
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '1073'
                                    }, this.showFilterInput ? _createElement('div', {
                                        'className': 'filter-input',
                                        'key': '1171'
                                    }, [this.filterInput(function () {
                                            return _createElement('div', {
                                                'className': 'input  cm_filterInput',
                                                'placeholder': 'Enter'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.inputNotEmpty ? _createElement('span', {
                                        'className': 'filter-input_clear-container',
                                        'onClick': this.clearInput,
                                        'key': '1491'
                                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '16601'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues overflowed' }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3251'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more'
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_selectFacet',
                                        'key': '3566'
                                    }, [this.select(function () {
                                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                                return [
                                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                        'value': '',
                                                        'key': 'null-option'
                                                    }, '\n          ', this.title, '\n        ') : null,
                                                    this.loading ? _createElement('option', {
                                                        'key': 'loading-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    isPopularField ? [
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10771'
                                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10774'
                                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                                    ] : null,
                                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                                ];
                                            }
                                            function scopeShowSelectedValues4() {
                                                var showSelectedValues = this.selectedEntries.length;
                                                return _createElement('option', {
                                                    'key': '_current',
                                                    'value': '_current'
                                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                                            }
                                            function repeatEntry5(entry, index) {
                                                return _createElement('div', {
                                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, this.showCheckboxes ? _createElement('input', {
                                                    'type': 'checkbox',
                                                    'readOnly': true,
                                                    'checked': entry.selected,
                                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                    'key': '3401'
                                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                    'className': 'cm_dropdown_hitCount',
                                                    'key': '3603'
                                                }, entry.hitCount) : null);
                                            }
                                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'key': '62'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'aria-label': this.title
                                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'aria-label': this.title,
                                                    'key': '2711'
                                                },
                                                !this.hideNullOption ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'onClick': () => this.onChange('')
                                                }, '\n      ', this.title, '\n    ') : null,
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                        'role': 'list',
                                        'className': 'cmTemplate_priceFacet',
                                        'key': '9573'
                                    }, !this.ranges.length ? [
                                        _createElement('div', { 'key': '96691' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                        '\n  ',
                                        this.slider,
                                        '\n  ',
                                        _createElement('div', {
                                            'className': 'cm_flex',
                                            'key': '96693'
                                        }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                                    ] : null, this.ranges.length ? [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cmRepeater_Values',
                                                'key': '99331'
                                            },
                                            _map(this.Values, repeatValues3.bind(this))
                                        ])] : null, [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Min price',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Max price',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn cm_btn button button--small button--secondary',
                                                'onClick': this.setCustomRange
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'sliderFacet' ? scopeInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'role': 'list',
                                        'className': 'cmTemplate_reviewFacet',
                                        'key': '14327'
                                    }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues6.bind(this))
                                    ])) : null)
                                ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_toggleFacet',
                                    'key': '17487'
                                }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                    'className': 'cm_facet-toggle_input',
                                    'type': 'checkbox',
                                    'checked': this.isToggled,
                                    'onClick': this.toggleFacet
                                }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:autoSyncVisualization","cm:WheelTireVehicleInfo","cm:filterChips","cm:FilterChips","cm:filterInput","cm:FacetBarWheels","cm:filterInput","cm:FacetBarTires","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:FacetPanel"]